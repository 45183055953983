* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
  overflow: hidden;
}

.messages-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 20px;
  max-height: 70vh; /* Limit the height of the chat container */
  overflow-y: auto; /* Enable scrolling for messages */
}

/* Mobile responsiveness with media query */
@media (max-width: 768px) {
  .chat-container {
    align-items: flex-start; /* Align the chat to the top */
  }

  .input-section {
    max-width: 90% !important;
  }

  .input-container {
    width: 100%; /* Allow the input container to take the full width */
  }

  .user-message,
  .bot-message {
    width: 80%; /* Adjust the width of messages */
    font-size: 12px; /* Reduce font size for readability */
  }

  .empty-message {
    margin-top: 10px; /* Reduce margin on mobile */
  }

  .input-container input {
    flex-grow: 1;
    padding: 15px; /* Increase padding for better touch interaction */
    border: none;
    outline: none;
    background: transparent;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px; /* Increase font size for better readability */
    color: #2835b9;
    height: 60px; /* Increase input height to make it larger */
  }
}

.chatbox {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.messages {
  overflow-y: auto;
  padding: 10px;
  flex-grow: 1;
}

/* Common style for all messages */
.message {
  margin: 3px 0; /* Reduce vertical margin */
  padding: 5px 10px; /* Reduce padding */
  border-radius: 8px;
  white-space: pre-wrap;
}

/* Styles for user messages */
.user-message {
  color: #2835b9;
  margin-bottom: 3px; /* Reduce space below user messages */
  font-family: 'Plus Jakarta Sans', sans-serif;
  width: fit-content;
  border-radius: 10px;
  border: 0.5px solid #2835b9;
  font-size: 14px;
  letter-spacing: 0.26px;
}

/* Styles for bot messages */
.bot-message {
  background-color: rgba(41, 86, 161, 0.1);
  color: rgba(41, 86, 161, 0.9);
  margin-bottom: 3px; /* Reduce space below bot messages */
  width: fit-content;
  border-radius: 10px;
  border: 0.5px solid #2835b9;
  font-size: 14px;
  letter-spacing: 0.26px;
}

/* Styles for input-section and "Powered by OpenAI" text */
.input-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  max-width: 40%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: -70px;
  position: relative;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(41, 86, 161, 0.1);
  border-radius: 8px;
  padding: 10px;
}

.input-container input {
  flex-grow: 1;
  padding: 10px; /* Increase padding for better touch interaction */
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px; /* Increase font size for better readability */
  color: #2835b9;
  height: 40px; /* Increase input height to make it larger */
}

.input-container i.fas.fa-paper-plane {
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  color: #2835b9;
  transition: transform 0.2s ease;
}

.input-container i.fas.fa-paper-plane:hover {
  transform: scale(1.1);
}

.empty-message {
  text-align: center;
  color: #2835b9;
  font-size: 16px;
  margin-top: 20px;
}

.powered-by {
  text-align: center;
  color: #2835b9;
  font-size: 12px;
  margin-top: 10px;
}
